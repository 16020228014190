import { IAugmentedJQuery, IComponentController, IPromise, IQService, IScope, ITimeoutService, element } from 'angular';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IMenuMoreClass, IMenuMoreTypes, IMenuMoreTypesOptions } from '../../services/menu/menu-more.service';
import { IMenuItemClass } from '../../services/menu/menu-item.service';
import IDialogOptions = angular.material.IDialogOptions;
import IStateService = angular.ui.IStateService;
import { IDialog } from '../../services/dialog.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IMenuClass } from '../../services/menu/menu.service';
import { IDefaultsService } from '../../services/utils/defaults.service';

interface IMultiOccurrenceHierarchieComposant extends IComponentController {
    dataList: Array<IMultiOccurrenceHierarchieDataListRow>;
    ecranContextCtrl: IEcranContextController;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    dataListOriginal: Array<IMultiOccurrenceHierarchieDataListRow>;
    loopHierarchie: boolean;
    width: number;
    effacerRecherche(event: Event): void;
    onRowClick(event: MouseEvent): void;
    retryErreur(): void;
    hasEmptyDataList(): boolean;
    onRechercheKeydown(event: KeyboardEvent): void;
    onRenderComplete(): void
    onRenderStart(): void
    handleWidthChange(width: number): void
}

interface IMultiOccurrenceHierarchieDataListRow {
    $$enfants?: Array<IMultiOccurrenceHierarchieDataListRow>
}

/* @ngInject */
export default function MultiOccurrenceHierarchieController(DialogSuiviModification: IDialog,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    MenuMore: IMenuMoreClass,
    MultiOccurrence: IMultiOccurrenceClass,
    $scope: IScope,
    $state: IStateService,
    $timeout: ITimeoutService,
    $q: IQService,
    defaults: IDefaultsService,
    $element: IAugmentedJQuery) {
    const vm: IMultiOccurrenceHierarchieComposant = this;

    vm.$onInit = $onInit;
    vm.onRenderComplete = onRenderComplete
    vm.onRenderStart = onRenderStart
    vm.handleWidthChange = handleWidthChange
    vm.onRowClick = onRowClick;
    vm.retryErreur = retryErreur;
    vm.hasEmptyDataList = hasEmptyDataList;
    vm.effacerRecherche = effacerRecherche;
    function $onInit() {
        defaults(vm, {
            loopHierarchie: false
        })
        const actionsDroite = (vm.multiOccurrenceOptions.actionsRangeeDroite) ? new Menu([...vm.multiOccurrenceOptions.actionsRangeeDroite.listeMenuItem]) : new Menu([]);

        vm.multiOccurrence = new MultiOccurrence(Object.assign({}, vm.multiOccurrenceOptions, {
            parentId: vm.monoOccurrenceCtrl.monoOccurrence.id,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            colonnesCachees: [
                ...(vm.multiOccurrenceOptions.colonnesCachees || []),
                vm.multiOccurrenceOptions.bris,
                vm.multiOccurrenceOptions.brisNiveaux
            ],
            fonctions: Object.assign({
                rechercheSansLimite: true,
                recherche: false,
                selectionnerUnEtat: false,
                rechercheParColonne: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                filtrer: false,
                enregistrerUnEtat: false,
                reinitialiser: false,
                exportation: false,
                importation: false,
                pagination: false
            }, vm.multiOccurrenceOptions.fonctions),
            actionsRangeeDroite: actionsDroite
        }));

        vm.multiOccurrence.once('ready', () => {
            $timeout(() => {
                initDataListWatcher();
                initActionsRangeeDroite();

                if (vm.monoOccurrenceEcranCtrl && vm.multiOccurrence.bloc) {
                    vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(vm.multiOccurrence);
                }
            }, 50);
        });

    }

    function onRenderComplete(): void {
        vm.loopHierarchie = true;
        const containerId = 'ex-mono-occurrence-scroll-y';
        const storageKey = 'ex-hierarchie-scrollPosition';

        const scrollableContainer: HTMLElement | null = document.getElementById(containerId);
        const scrollPosition: string | null = sessionStorage.getItem(storageKey);

        if (scrollPosition) {
            const parsedScrollPosition = parseInt(scrollPosition, 10);
            if (!isNaN(parsedScrollPosition) && parsedScrollPosition > 0) {
                scrollableContainer.scrollTop = parsedScrollPosition;
                sessionStorage.removeItem(storageKey);
            } 
        }
    }

    function onRenderStart(): void {
        vm.loopHierarchie = false;
        const targetElement = $element.find("#ex-hierarchie-item-content");
        
        if (!targetElement || !targetElement[0]) {
            return;
        }

        if (typeof vm.width !== "number" || vm.width <= 0) {
            return;
        }
    
        targetElement[0].setAttribute("width", String(vm.width));
    }

    function handleWidthChange(width: number): void {
        vm.width = width;
    }

    function onRowClick(event: MouseEvent) {
        const targetElement = element(event.target);
        if (!targetElement.hasClass('md-icon-button') && !targetElement.hasClass('ex-toggle-icon')) {
            vm.multiOccurrence.activeRowCleint = targetElement.closest('ex-hierarchie-item').controller('exHierarchieItem').data[vm.multiOccurrence.cleint];
        }
    }

    function initActionsRangeeDroite() {
        const menuMoreTypes: IMenuMoreTypes = {};
        const menuMoreTypesOptions: IMenuMoreTypesOptions = {};

        if (vm.multiOccurrence.hasAccesMenuEmploye) {
            menuMoreTypes.dossierEmploye = true;
        }

        if (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) {
            menuMoreTypes.suiviModification = (event: any, rowDetails: any) => {
                afficherSuiviModification(event, rowDetails);
            };

            if (vm.multiOccurrence.fonctions.suiviModification instanceof Function) {
                menuMoreTypesOptions.suiviModification = {
                    visible: vm.multiOccurrence.fonctions.suiviModification
                };
            }
        }

        if ((vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) ||
            (vm.multiOccurrence.actionsMoreLigne && vm.multiOccurrence.actionsMoreLigne.listeMenuItem.length)) {
            const menuMore = new MenuMore(menuMoreTypes, vm.multiOccurrence.actionsMoreLigne, menuMoreTypesOptions);
            vm.multiOccurrence.actionsRangeeDroite.add(menuMore);
        }

        if (vm.multiOccurrenceOptions.navigatePage) {
            vm.multiOccurrence.actionsRangeeDroite.add(
                new MenuItem('G_LBL_BTN_OUVRIR', getPageLink, {
                    icon: 'exit_to_app',
                    link: true,
                    fonction: `${vm.multiOccurrence.mnemonique}.BOUOUV`,
                    directionTooltip: 'bottom'
                })
            );
        }
    }

    function getPageLink(data: any) {
        const params = vm.multiOccurrence.navigateParams ? vm.multiOccurrence.navigateParams(data, vm.multiOccurrence) : {};

        const pageName = vm.multiOccurrence.navigatePage instanceof Function ? vm.multiOccurrence.navigatePage(data) : vm.multiOccurrence.navigatePage;

        return $state.href(pageName, {
            id: data[vm.multiOccurrence.forageCleint],
            menuId: vm.ecranContextCtrl.stateParams.menuId,
            ...params
        });
    }

    function afficherSuiviModification(event: MouseEvent, rowDetails: any) {
        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: {
                data: rowDetails,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogSuiviModification.show(dialogOptions);
    }

    function initDataListWatcher() {
        $scope.$watch('::vm.multiOccurrence', (multiOccurrence: IMultiOccurrence) => {
            if (multiOccurrence) {
                if (vm.multiOccurrence.dataListReady) {
                    $timeout(() => {
                        //on efface le local storage afin de reinitialiser l'arbre
                        deleteDataLocal().then((() => {
                            vm.dataList = formatDataList(vm.multiOccurrence.dataList);
                            vm.dataListOriginal = vm.dataList
                        }))

                    }, 50)
                }

                multiOccurrence.on('dataListUpdate', () => {
                    vm.dataList = formatDataList(vm.multiOccurrence.dataList);
                    vm.dataListOriginal = vm.dataList
                    //s'il il a une valeur dans la boite de texte on execute la recherche
                    if (vm?.value) {
                        executeRecherche(false)
                    }
                });

                multiOccurrence.on('dataListUpdateError', () => {
                    vm.dataList = [];
                });
            }
        });
    }

    function formatDataList(dataList: Array<any>): Array<IMultiOccurrenceHierarchieDataListRow> {
        return dataList.reduce((newDataList: Array<IMultiOccurrenceHierarchieDataListRow>, rowData: any) => {
            let parent = newDataList;
            for (let n = 1, l = rowData[vm.multiOccurrence.brisNiveaux]; n < l; n++) {
                const initObj: IMultiOccurrenceHierarchieDataListRow[] = []
                const currentItem: any = parent[parent.length - 1] || initObj;
                currentItem.$$enfants = currentItem.$$enfants || [];
                parent = currentItem.$$enfants;
            }
            parent.push(rowData);
            return newDataList;
        }, []);
    }

    function retryErreur() {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.autoFetch = true;
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }

    function hasEmptyDataList() {
        return !vm.multiOccurrence.initError && !vm.multiOccurrence.fetchingDataList && !vm.multiOccurrence.dataListError && (!vm.multiOccurrence.dataList || vm.multiOccurrence.dataList.length === 0)
    }

    function getNomItemLocalStorage() {
        //on obtiens un key pour le locastorage par ecran et id
        let id = vm?.multiOccurrence?.stateParams?.id + "" || 0 + ""
        let result = (vm?.multiOccurrence?.srccod) ? vm.multiOccurrence.srccod + id + "exHierarchieItem" : "noName";
        return result
    }

    function deleteDataLocal(): IPromise<boolean> {
        let data = getNomItemLocalStorage()
        if (data && localStorage.getItem(data)) {
            localStorage.removeItem(data)
        }
        return $q.resolve(true);
    }

    vm.onRechercheKeydown = function onRechercheKeydown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            $timeout(() => {
                //chaque fois qu'on effectue une recherche on met l'arbre ferme
                deleteDataLocal().then((() => {
                    vm.multiOccurrence.fetchDataList().then(() => {
                        executeRecherche(true)
                    });
                }))
            }, 50)
        }
    }

    function executeRecherche(flag: boolean) {
        //on met les executions en sequence 
        setDatalistOriginal().then(() => {
            //s'il y a un texte valide on fait la recherche
            if (vm?.value && vm?.value.trim() !== "") {
                vm.dataList = rechercherArray(vm.dataList, vm.value)
            }
        })
    }
    function setDatalistOriginal(): IPromise<boolean> {

        if (!vm?.dataListOriginal) {
            vm.dataListOriginal = formatDataList(vm.multiOccurrence.dataList);
        }

        //on met le datalist original juste lorsqu'on a fait un filtre
        if (vm?.dataListOriginal && vm?.dataList && vm.dataList.length !== vm.dataListOriginal.length) {
            vm.dataList = vm.dataListOriginal
        }
        return $q.resolve(true);
    }

    function effacerRecherche() {
        vm.value = ""
        //on met l'arbre ferme
        deleteDataLocal().then(() => {
            vm.multiOccurrence.fetchDataList().then(() => { setDatalistOriginal() })
        })

    }

    function rechercherArray(tree: any[], searchText: string): any[] {
        //on met le texte au minuscule et on valide le valeur null
        searchText = (typeof (searchText) === "undefined") ? "" : searchText.toLowerCase()
        const result = tree
            .map(node => rechercherText(node, searchText))
            .filter(node => node !== null) as any[];

        return result as any[]
    }

    function rechercherText(node: any, searchText: string): any | null {
        // on recherche dans les enfants de façon recursive
        const children = node?.$$enfants
            ? node.$$enfants
                .map((child: any) => rechercherText(child, searchText))
                .filter((child: any) => child !== null) as any[]
            : [];

        let flagRecherche = false;
        vm.multiOccurrenceOptions.colonnesVisibles.forEach(((el: any) => {
            // Vérifier si la colonne du node actuel contient le texte recherché
            let col = (typeof (el) === "string") ? el : el.nom
            if (col && node && node[col] && node[col].toLowerCase().includes(searchText)) {
                flagRecherche = true;
                return
            }
        }))

        // Si des enfants contiennent le texte recherché, inclure ce node avec les enfants filtrés.
        if (flagRecherche || children.length > 0) {
            return { ...node, $$enfants: children.length > 0 ? children : undefined };
        }

        // Si aucun enfant ne contient le texte recherché, renvoyer null
        return null;
    }
}
